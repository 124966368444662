import { useSession } from "@core/hooks/useSession";
import useUser from "@core/hooks/useUser";
import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { isEmpty } from "lodash/fp";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";

import { useSortOptionsStore } from "../components/ActionBar";
import transformPaginatedPosts from "../transformers/transformPaginatedPosts";
import {
  ApiPaginatedPosts,
  FeedContentFilters,
  PaginatedPosts,
} from "../types";
import { UserFeedSettings, useUserFeedSettings } from "./useUserFeedSettings";

const buildSettingsKey = (userFeedSettings: UserFeedSettings): string => {
  const filters = (userFeedSettings?.filters as string[]) ?? [];
  return !isEmpty(filters) ? `&filters=${filters.join(",")}` : ``;
};

interface GetQueryStringOptions {
  segmentId?: string;
  filters?: string | string[];
  subtype?: string;
  sortBy?: "new" | "popular";
}
const getQueryString = ({
  segmentId,
  filters,
  subtype,
  sortBy,
}: GetQueryStringOptions) => {
  return (
    Object.entries({
      segment_id: segmentId,
      filters,
      limit: 100,
      topic_ids: subtype,
      sort_by: sortBy,
      new_feed: segmentId ? undefined : 1,
      feed_type: subtype ? "topics" : null,
    })
      .filter(([_, value]) =>
        Array.isArray(value) ? value.length > 0 : !!value
      )
      // if the value is an array, make it a column delimited string
      .map(([key, value]) => [
        key,
        Array.isArray(value) ? value.join(",") : String(value),
      ])
      .map((item) => item.join("="))
      .join("&")
  );
};

export const getInfinitePostsQueryKey = (
  accessToken: string,
  userFeedSettings?: UserFeedSettings,
  subtype?: string,
  sortBy?: "Most recent" | "Most popular"
): string[] =>
  [
    "all-posts",
    accessToken,
    buildSettingsKey(userFeedSettings),
    subtype,
    sortBy,
  ].filter(Boolean);

export const getPosts = (
  segmentId?: string,
  deviceId?: string,
  accessToken?: string,
  userFeedSettings?: UserFeedSettings,
  subtype?: string,
  sortBy?: "new" | "popular"
): Promise<PaginatedPosts> => {
  const queryString = getQueryString({
    segmentId,
    subtype,
    filters: userFeedSettings?.filters,
    sortBy,
  });

  return api
    .get<ApiPaginatedPosts>(`/v3/posts?${queryString}`, {
      headers: {
        "X-DeviceID": deviceId,
        Authorization: accessToken,
      },
    })
    .then(({ data: page }) => transformPaginatedPosts(page));
};

export const useInfinitePosts = (
  subtypeId?: string
): UseInfiniteQueryResult<PaginatedPosts, AxiosError> => {
  const { data: session } = useSession();
  const { accessToken, deviceId } = session ?? {};
  const { data: user } = useUser();
  const isChildAcct = !user?.isRootAccount && user?.linkedAccounts?.length;
  const { sortBy } = useSortOptionsStore();

  const { feedSettingsByUser } = useUserFeedSettings();
  const userFeedSettings = isChildAcct
    ? { filters: [FeedContentFilters.NOCD] }
    : feedSettingsByUser?.[session?.user?.id];

  return useInfiniteQuery(
    getInfinitePostsQueryKey(accessToken, userFeedSettings, subtypeId, sortBy),
    ({ pageParam: segmentId }) =>
      getPosts(
        segmentId as string,
        deviceId,
        accessToken,
        userFeedSettings,
        subtypeId,
        sortBy === "Most recent" ? "new" : "popular"
      ),
    {
      getNextPageParam: ({ cursors }) => cursors.next,
      enabled: !!accessToken,
    }
  );
};
