import { api } from "@core/services/nocd-api";
import { AxiosError } from "axios";
import { useQuery, UseQueryResult } from "react-query";

import transformPaginatedPosts from "../transformers/transformPaginatedPosts";
import { ApiPaginatedPosts, PaginatedPosts } from "../types";

export const getPublicPostsQueryKey = ({
  segmentId,
  subtype,
  sortBy = "Most recent",
}: {
  segmentId: string;
  subtype: string;
  sortBy?: "Most recent" | "Most popular";
}): string[] => ["public-posts", segmentId, subtype, sortBy].filter(Boolean);

export const getPublicPosts = ({
  segmentId,
  subtype,
  signal,
  sortBy = "new",
}: {
  segmentId?: string;
  subtype?: string;
  signal?: AbortSignal;
  sortBy?: "new" | "popular";
}): Promise<PaginatedPosts> => {
  // build the query for the request
  // note :: if the segment id isn't passed, select the new feed
  const query = Object.entries({
    segment_id: segmentId,
    // filters: "my_topics",
    limit: 20,
    subtype,
    sort_by: sortBy === "popular" ? "popular" : "new",
    feed_type: subtype ? "topics" : null,
  })
    .filter(([_, value]) => !!value)
    .map((item) => item.join("="))
    .join("&");

  return api
    .get<ApiPaginatedPosts>(`/v3/posts?${query}`, { signal })
    .then(({ data: page }) => transformPaginatedPosts(page));
};

export const usePublicPosts = (
  segmentId?: string,
  subtype?: string
): UseQueryResult<PaginatedPosts, AxiosError> => {
  return useQuery({
    queryKey: getPublicPostsQueryKey({
      segmentId,
      subtype,
    }),
    queryFn: ({ signal }) =>
      getPublicPosts({ segmentId, subtype, signal, sortBy: "new" }),
  });
};
