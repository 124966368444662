import Button from "@core/ui/Button";
import { useAuthenticationModal } from "@features/auth";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import cn from "classnames";
import { FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";

const defaultCopy = (
  <p className="laptop:text-24px font-bold leading-tight">
    Be part of the world&apos;s <br className="tablet:hidden" /> largest OCD
    community
  </p>
);

const StickyFooterCta: FC<{ copy?: ReactNode }> = ({ copy = defaultCopy }) => {
  const defaultOpenWithDelay = true;

  const [isOpen, setIsOpen] = useState(false);

  const alreadyOpened = useRef(false);

  const openModal = useCallback(() => {
    alreadyOpened.current = true;
    return setIsOpen(true);
  }, []);

  const { openModal: openAuthModal } = useAuthenticationModal();

  useEffect(() => {
    if (alreadyOpened.current) {
      return undefined;
    }

    if (!defaultOpenWithDelay) {
      return undefined;
    }

    const timeoutId = setTimeout(() => {
      if (alreadyOpened.current) {
        return;
      }

      openModal();
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [defaultOpenWithDelay, openModal]);

  const closeModal = useCallback(() => setIsOpen(false), []);

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div
        style={{
          background:
            "linear-gradient(101.11deg, #FBD79A 1.03%, #DBC4F7 51.01%, #B3BAF7 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.9) -82.16%, rgba(255, 255, 255, 0.1) 100%)",
        }}
        className={cn(
          "w-full fixed z-10 bottom-0 left-0 flex",
          "justify-center py-6 laptop:py-8"
        )}
      >
        <div className="flex flex-col laptop:flex-row items-center gap-2 laptop:gap-6">
          {copy}

          <div>
            <Button
              color="indigo"
              className="!rounded-lg !py-3 laptop:!py-4 px-12 !text-14px !font-semibold"
              onClick={() => {
                closeModal();
                openAuthModal();
              }}
            >
              Join
            </Button>
          </div>
        </div>

        <button
          type="button"
          onClick={() => closeModal()}
          className="absolute top-3 right-3 laptop:top-4 laptop:right-4 p-1"
        >
          <p className="sr-only">close</p>{" "}
          <XIcon className="laptop:w-6 laptop:h-6 w-5 h-5 text-v2-indigo-600" />
        </button>
      </div>
    </Transition>
  );
};

export default StickyFooterCta;
