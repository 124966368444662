import { useSession } from "@core/hooks/useSession";
import IntakeCallSchedulerModal from "@core/scheduler-modals/IntakeCallSchedulerModal";
import { api } from "@core/services/nocd-api";
import Button from "@core/ui/Button";
import Loader from "@core/ui/Loader";
import React, { FC, useState } from "react";
import { isBrowser } from "react-device-detect";
import { useQuery } from "react-query";
import Sticky from "react-stickynode";

import { TopCommunityPost } from "../types";
import PostCard from "./PostCard";

export const SidebarCta: FC = () => {
  const [isIntakeSchedulerModalOpen, setIsIntakeSchedulerModalOpen] =
    useState(false);

  return (
    <>
      <div className="py-4">
        <div
          style={{
            backgroundImage: "url(/images/community/sidebar-cta-bg.svg)",
            backgroundSize: "110%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="relative rounded-2xl overflow-hidden shadow"
        >
          <div className="relative z-1 flex flex-col items-center justify-center pt-18 pb-16 gap-6">
            <p className="text-24px font-bold text-center">
              OCD doesn&apos;t have to <br /> rule your life
            </p>

            <Button
              color="indigo"
              className="!px-4 !py-3 !font-semibold !rounded-lg"
              onClick={() => setIsIntakeSchedulerModalOpen(true)}
            >
              Book a free call
            </Button>
          </div>
        </div>
      </div>

      <IntakeCallSchedulerModal
        isOpen={isIntakeSchedulerModalOpen}
        handleClose={() => setIsIntakeSchedulerModalOpen(false)}
        source="sidebar"
      />
    </>
  );
};

const useRecentPosts = (currentPostId: number) => {
  const { data: session } = useSession();
  return useQuery(
    ["recent-posts", session?.accessToken],
    ({ signal }) =>
      api
        .get<{ posts: TopCommunityPost[] }>("/v1/community/discover_feed", {
          signal,
          headers: session?.accessToken
            ? {
                Authorization: session?.accessToken,
              }
            : {},
        })
        .then<TopCommunityPost[]>(({ data }) =>
          data?.posts
            ?.filter((post) => post.type === "top_community_post")
            ?.filter((post) => post.data?.id !== currentPostId)
            .slice(0, 3)
        ),
    {
      staleTime: Infinity,
    }
  );
};

interface Props {
  currentPostId?: number;
}

const RecentPostsSidebar: FC<Props> = ({ currentPostId }) => {
  const { data, isLoading } = useRecentPosts(currentPostId || null);
  const { data: session } = useSession();

  if (isLoading) {
    return (
      <div className="flex text-32px text-indigo-600 justify-center items-center py-12">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Sticky enabled={isBrowser}>
        <div className="grid bg-white rounded-xl shadow">
          <h2 className="px-5 font-bold text-20px pt-8 pb-5">Recent Posts</h2>

          <div className="divide-y divide-gray-100">
            {data?.map((post) => (
              <section
                key={post.data?.id}
                className="p-5 tablet:mx-0 tablet:shadow-sm overflow-hidden cursor-pointer"
              >
                <PostCard
                  id={String(post.data.id)}
                  onClickReply={{
                    type: "link",
                    href: `/community/posts/${post.data.id}`,
                  }}
                  post={post.data}
                  rootPostId={post.data.id}
                  variant="sidebar"
                />
              </section>
            ))}
          </div>

          <div className="p-5">
            If you are in crisis, please use these{" "}
            <a
              className="text-indigo-600 underline"
              href={`${process.env.NEXT_PUBLIC_WEBSITE_BASE_URL}/emergency-resources`}
            >
              emergency resources
            </a>{" "}
            to find immediate help.
          </div>
        </div>

        {session ? <SidebarCta /> : null}
      </Sticky>
    </>
  );
};

export default React.memo(RecentPostsSidebar);
