import { getQueryKey } from "@core/hooks/useSession";
import { APIErrorType } from "@core/services/nocd-api";
import { SSRError } from "@core/types";
import getServerSession from "@core/utils/getServerSession";
import {
  getPublicPosts,
  getPublicPostsQueryKey,
  MainFeedScreen,
} from "@features/community-v2";
import {
  getCategorizedTopics,
  getTopicsQueryKey,
} from "@features/community-v2/hooks/useTopics";
import { GetServerSideProps } from "next";
import { NextSeo } from "next-seo";
import { QueryClient } from "react-query";
import { dehydrate } from "react-query/hydration";

const PAGE_TITLE =
  "OCD Community | Obsessive-Compulsive Disorder Forum and Discussion";
const PAGE_DESCRIPTION =
  "The NOCD Community provides support for people with OCD. You can meet others, share your experiences, and learn from others on a similar journey with OCD.";

interface CommunityHomePageProps {
  segmentId?: string;
  ssrError: SSRError;
}

const CommunityHomePage = ({
  segmentId,
  ssrError,
}: CommunityHomePageProps): JSX.Element => {
  return (
    <>
      <NextSeo
        title={PAGE_TITLE}
        description={PAGE_DESCRIPTION}
        openGraph={{
          description: PAGE_DESCRIPTION,
          title: PAGE_TITLE,
        }}
        canonical={`${process.env.NEXT_PUBLIC_CANONICAL_URL}/community/posts`}
      />
      <MainFeedScreen segmentId={segmentId} ssrError={ssrError} />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const queryClient = new QueryClient();
  const session = await getServerSession(ctx);

  queryClient.setQueryData(getQueryKey(), session);

  // If an error happens during SSR, we want to capture it and pass it to the client.
  let ssrError: SSRError = null;

  // If the user is not logged in, we want to pre-fill the query cache,
  // so the content is in the initial HTML. This is needed for SEO.
  if (!session) {
    try {
      const firstPage = await getPublicPosts({
        segmentId: ctx?.query?.segmentId as string,
      });

      queryClient.setQueryData(
        getPublicPostsQueryKey({
          subtype: null,
          sortBy: "Most recent",
          segmentId: ctx.query.segmentId as string,
        }),
        firstPage
      );

      const topics = await getCategorizedTopics();
      queryClient.setQueryData(getTopicsQueryKey(), topics);
    } catch (error) {
      if (
        error instanceof Error &&
        error?.name === APIErrorType.INVALID_PERMISSIONS
      ) {
        return {
          redirect: {
            destination: `/login?redirectTo=/community/posts`,
            permanent: false,
          },
        };
      }

      if (error instanceof Error) {
        ssrError = {
          name: error.name,
          message: error.message,
          stack: error.stack ?? null,
        };
      }
    }
  }

  return {
    props: {
      dehydratedState: dehydrate(queryClient),
      segmentId: ctx.query.segmentId ?? null,
      ssrError,
    },
  };
};

// The page is publicly accessible, so we want to show the OneTrust consent
// dialog to the user if they are not logged in.
CommunityHomePage.shouldShowConsentDialog = true;
CommunityHomePage.backgroundColor = "gray-v2";
CommunityHomePage.hideNavbarShadowOnMobile = true;
CommunityHomePage.hideNavbarShadowOnDesktop = true;

export default CommunityHomePage;
